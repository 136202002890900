<template>
  <div :class="[isSmallScreen ? 'vertical-responsive-sm' : '', contentWidth === 'boxed' ? 'container p-0' : '']"  >
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  data() {
    return {
      isSmallScreen: false,
    };
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.isSmallScreen = window.innerWidth <= 767;
    },
  },
  setup() {
    const { contentWidth } = useAppConfig()
    
    return { contentWidth }
  },
}
</script>
